import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js";
import Seo from "../components/seo"
import HeroTitle from "../components/hero-title/hero-title"
import $ from 'jquery'


class PageTemplate extends Component {
	constructor(props){
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			formMessage : '',
			loader: false
		}
	}


	handleSubmit(event){


		event.preventDefault();
		this.setState({
			loader : true
		})
		var formdata = new FormData($('form')[0]);
		// formData.append("_wpcf7_unit_tag", 'a123c5e');
		formdata.append("your-org-name", this.OrgName.value);
		formdata.append("your-address", this.address.value);
		formdata.append("your-city", this.city.value);
		formdata.append("member-1-state", this.memberState.value);
		formdata.append("your-zip", this.zip.value);
		formdata.append("your-website", this.website.value);
		formdata.append("your-contact-name", this.contactName.value);
		formdata.append("your-contact-title", this.contactTitle.value);
		formdata.append("your-email", this.email.value);
		formdata.append("your-phone", this.phone.value);
		formdata.append("your-tax-id", this.taxID.value);
		if(this.fileW9 && this.fileW9.files) {
			formdata.append("file-w9", this.fileW9.files[0]);
		}
		if(this.file501C3 && this.file501C3.files) {
			formdata.append("file-501-c-3", this.file501C3.files[0]);
		}
		formdata.append("focus-area", this.focusArea.value);
		formdata.append("your-objectives", this.objectives.value);
		formdata.append("your-volunteers", this.volunteers.value);
		formdata.append("your-amount-requested", this.amountRequested.value);
		formdata.append("received-contribution", this.receivedContribution.value);
		formdata.append("your-desc-of-request", this.descRequest.value);
		if(this.fileAdditionalInfo && this.fileAdditionalInfo.files) {
			formdata.append("file-additional-info", this.fileAdditionalInfo.files[0]);
		}
		formdata.append("your-community", this.community.value);
		formdata.append("your-recognition", this.recognition.value);
		const options = {
		  method: 'POST',
		  body: formdata,
		  headers:{
		  	'Content-Type': 'multipart/form-data'
		  }
		};
		delete options.headers['Content-Type'];
		fetch('https://alphametresources.com/grant-form', options).then(res => res.json())
			.then(res => {
				$('.wpcf7 label .wpcf7-not-valid-tip').remove();
				if(res.status === "validation_failed") {
					$('.wpcf7-response-output').addClass('form-error');
					$('.wpcf7-response-output').removeClass('form-success');
					res.invalid_fields.map(field => {
						if(field) {
							$(field.into).append('<span class="wpcf7-not-valid-tip" aria-hidden="true">' + field.message + '</span>');
						}
					});

				} else if(res.status === "mail_sent") {
					$('.wpcf7-form').trigger("reset");
					$('.wpcf7-response-output').removeClass('form-error');
					$('.wpcf7-response-output').addClass('form-success');
				}
				this.setState({
					formMessage : res.message,
					loader: false
				})
			});
	 };


	componentDidMount() {
		setTimeout(function(){

			var iFrameID = document.getElementById('dynamic-height');
			if(iFrameID) {
				// here you can make the height, I delete it first, then I make it again
				iFrameID.height = "";
				iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
			}
		}, 2000 );

		$('.header-links .header-link').each(function() {
			if($(this).attr('href') === window.location.pathname) {
				$(this).addClass('active');
			}
		});
		if (window.location.pathname.indexOf('/about/') >= 0) {
			$('.header-links .header-link[href="/about/"]').addClass('active')
		}
	}
	render() {
		const currentPage = this.props.data.wpPage
		const aboutSubmenu = this.props.data.wpMenu
		return (
			<Layout>
			<Seo
				title={currentPage.title}
				contentBody={currentPage.body}
				locationOrigin={this.props.location.origin + currentPage.uri}
				domen={this.props.location.origin}
				image={currentPage.featuredImage ?  currentPage.featuredImage.node.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
			/>
				{currentPage.slug === 'about' || (currentPage.wpParent && currentPage.wpParent.node.slug === 'about') ? (
					<div className="sub-menu">
						<div className="container">
							<ul>
								{aboutSubmenu && aboutSubmenu.menuItems.nodes.map(item => (
									<li key={item.url}>
										<Link className={currentPage.uri === item.url ? ( 'active' ) : ( null )} to={item.url}>{item.label}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				) : (
					null
				)}
				<header className="master-header video-header">
					{currentPage.headerBackground !== false && currentPage.headerBackground.vimeoId !== false ? (
						<>
							<img  src={`/rings.svg`} alt="loader" className="loader" />
							<iframe src={'https://player.vimeo.com/video/' + currentPage.headerBackground.vimeoId + '?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="fade-in'} title="vimeo video"></iframe>
							{/*
								<div className="container">
									<div className="page-header">
										<h1 className="page-title">{currentPage.title}</h1>
									</div>
								</div>
							*/}
						</>
					) : (
						null
					)}
				</header>
				{currentPage?.title &&
					<HeroTitle title={currentPage?.title} />
				}
				<section className="main-content">
					<div className="container">
						{currentPage.sidebarWidgetRelation !== null && currentPage.sidebarWidgetRelation.sidebarWidgetRelations !== null ? (
							<>
							<div className="uk-grid" data-uk-grid>
								<div className="uk-width-2-3@m main-col contact-us-page">
									{currentPage.svgInteractiveMap && currentPage.svgInteractiveMap.svgMap ? (
										<div dangerouslySetInnerHTML={{__html: currentPage.svgInteractiveMap.svgMap}} />
									) : (
										null
									)}

									<div dangerouslySetInnerHTML={{__html: currentPage.content}} />
									{currentPage.contactUs && currentPage.contactUs.location ? (
										<>
											{currentPage.contactUs.location[0].addresses.map((item, index) => (
												<div className="uk-grid contact-us-grid" data-uk-grid>
													<div className="uk-width-1-2@s left-column">
														<h4>{currentPage.contactUs.location[0].leftColumnTitle}</h4>
														<div key={index} dangerouslySetInnerHTML={{__html: item.physicalAddress}}></div>
													</div>
													<div className="uk-width-1-2@s right-column">
														<h4>{currentPage.contactUs.location[0].rightColumnTitle}</h4>
														{currentPage.contactUs.location[0].addresses.map((item, i) => (
															i === index ?
															<div key={index} dangerouslySetInnerHTML={{__html: item.mailingAddress}}></div>
															: null
														))}
													</div>
												</div>
											))}
										</>
									) : (
										null
									)}

									<div id="grant-request">
										<div role="form" className="wpcf7" id="wpcf7-f725-o1" lang="en-US" dir="ltr">
											<div className="screen-reader-response"></div>
											<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post" className="wpcf7-form">

												<label> Organization Name<br />
													<span className="wpcf7-form-control-wrap your-org-name">
													<input ref={(ref) => {this.OrgName = ref}} type="text" name="your-org-name" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Address<br />
													<span className="wpcf7-form-control-wrap your-address">
													<input ref={(ref) => {this.address = ref}} type="text" name="your-address" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> City<br />
													<span className="wpcf7-form-control-wrap your-city">
													<input ref={(ref) => {this.city = ref}} type="text" name="your-city" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> State<br />
													<span className="wpcf7-form-control-wrap member-1-state">
														<select ref={(ref) => {this.memberState = ref}} name="member-1-state" className="wpcf7-form-control wpcf7-select" aria-invalid="false">
															<option value="">---</option>
															<option value="Alabama">Alabama</option>
															<option value="Alaska">Alaska</option>
															<option value="Arizona">Arizona</option>
															<option value="Arkansas">Arkansas</option>
															<option value="California">California</option>
															<option value="Colorado">Colorado</option>
															<option value="Connecticut">Connecticut</option>
															<option value="Delaware">Delaware</option>
															<option value="Florida">Florida</option>
															<option value="Georgia">Georgia</option>
															<option value="Hawaii">Hawaii</option>
															<option value="Idaho">Idaho</option>
															<option value="Illinois">Illinois</option>
															<option value="Indiana">Indiana</option>
															<option value="Iowa">Iowa</option>
															<option value="Kansas">Kansas</option>
															<option value="Kentucky">Kentucky</option>
															<option value="Louisiana">Louisiana</option>
															<option value="Maine">Maine</option>
															<option value="Maryland">Maryland</option>
															<option value="Massachusetts">Massachusetts</option>
															<option value="Michigan">Michigan</option>
															<option value="Minnesota">Minnesota</option>
															<option value="Mississippi">Mississippi</option>
															<option value="Missouri">Missouri</option>
															<option value="Montana">Montana</option>
															<option value="Nebraska">Nebraska</option>
															<option value="Nevada">Nevada</option>
															<option value="New Hampshire">New Hampshire</option>
															<option value="New Jersey">New Jersey</option>
															<option value="New Mexico">New Mexico</option>
															<option value="New York">New York</option>
															<option value="North Carolina">North Carolina</option>
															<option value="North Dakota">North Dakota</option>
															<option value="Ohio">Ohio</option>
															<option value="Oklahoma">Oklahoma</option>
															<option value="Oregon">Oregon</option>
															<option value="Pennsylvania">Pennsylvania</option>
															<option value="Rhode Island">Rhode Island</option>
															<option value="South Carolina">South Carolina</option>
															<option value="South Dakota">South Dakota</option>
															<option value="Tennessee">Tennessee</option>
															<option value="Texas">Texas</option>
															<option value="Utah">Utah</option>
															<option value="Vermont">Vermont</option>
															<option value="Virginia">Virginia</option>
															<option value="Washington">Washington</option>
															<option value="West Virginia">West Virginia</option>
															<option value="Wisconsin">Wisconsin</option>
															<option value="Wyoming">Wyoming</option>
															<option value="District of Columbia">District of Columbia</option>
															<option value="Puerto Rico">Puerto Rico</option>
															<option value="Guam">Guam</option>
															<option value="American Samoa">American Samoa</option>
															<option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
															<option value="Northern Mariana Islands">Northern Mariana Islands</option>
														</select>
														<br />
													</span>
												</label>
												<br />

												<label> Zip<br />
													<span className="wpcf7-form-control-wrap your-zip">
													<input ref={(ref) => {this.zip = ref}} type="text" name="your-zip" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Website (optional)<br />
													<span className="wpcf7-form-control-wrap your-website">
													<input ref={(ref) => {this.website = ref}} type="text" name="your-website" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Contact Name<br />
													<span className="wpcf7-form-control-wrap your-contact-name">
													<input ref={(ref) => {this.contactName = ref}} type="text" name="your-contact-name" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Contact Title<br />
													<span className="wpcf7-form-control-wrap your-contact-title">
													<input ref={(ref) => {this.contactTitle = ref}} type="text" name="your-contact-title" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Email Address<br />
													<span className="wpcf7-form-control-wrap your-email">
													<input ref={(ref) => {this.email = ref}} type="email" name="your-email" size="40" className="wpcf7-form-control wpcf7-email" /><br /></span>
												</label>
												<br />

												<label> Phone Number (000-000-0000)<br />
													<span className="wpcf7-form-control-wrap your-phone">
													<input ref={(ref) => {this.phone = ref}} type="text" name="your-phone" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Tax ID<br />
													<span className="wpcf7-form-control-wrap your-tax-id">
													<input ref={(ref) => {this.taxID = ref}} type="text" name="your-tax-id" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> W-9 form (please attach a .pdf document up to 5mb)<br />
													<span className="wpcf7-form-control-wrap file-w9">
													<input ref={(ref) => {this.fileW9 = ref}} type="file" name="file-w9" size="40" className="wpcf7-form-control wpcf7-file" accept=".pdf" /><br /></span>
												</label>
												<br />

												<label> IRS 501(c)(3) verification letter (please attach a .pdf document up to 5mb)<br />
													<span className="wpcf7-form-control-wrap file-501-c-3">
													<input ref={(ref) => {this.file501C3 = ref}} type="file" name="file-501-c-3" size="40" className="wpcf7-form-control wpcf7-file" accept=".pdf" /><br /></span>
												</label>
												<br />

												<label> Focus Area<br />
													<span className="wpcf7-form-control-wrap focus-area">
														<select ref={(ref) => {this.focusArea = ref}} name="focus-area" className="wpcf7-form-control wpcf7-select" aria-invalid="true">
															<option value="">---</option>
															<option value="coal country community development">coal country community development</option>
															<option value="hunger relief">hunger relief</option>
															<option value="youth development">youth development</option>
															<option value="other">other</option>
														</select>
														<br />
													</span>
												</label>
												<br />

												<label> Organization Overview and Objectives<br />
													<span className="wpcf7-form-control-wrap your-objectives">
														<textarea ref={(ref) => {this.objectives = ref}} name="your-objectives" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"></textarea>
														<br />
													</span>
												</label>
												<br />

												<label> Amount Requested<br />
													<span className="wpcf7-form-control-wrap your-amount-requested">
													<input ref={(ref) => {this.amountRequested = ref}} type="text" name="your-amount-requested" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> List of volunteers employed by Alpha Metallurgical Resources or affiliate (optional)<br />
													<span className="wpcf7-form-control-wrap your-volunteers">
													<input ref={(ref) => {this.volunteers = ref}} type="text" name="your-volunteers" size="40" className="wpcf7-form-control wpcf7-text" /><br /></span>
												</label>
												<br />

												<label> Has your organization received a contribution from Alpha Metallurgical Resources this year?<br />
													<span className="wpcf7-form-control-wrap received-contribution">
														<select ref={(ref) => {this.receivedContribution = ref}} name="received-contribution" className="wpcf7-form-control wpcf7-select" aria-invalid="true">
															<option value="No">No</option>
															<option value="Yes">Yes</option>
														</select>
														<br />
													</span>
												</label>
												<br />

												<label> Detailed description of request including how funds will be used<br />
													<span className="wpcf7-form-control-wrap your-desc-of-request">
														<textarea ref={(ref) => {this.descRequest = ref}} name="your-desc-of-request" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"></textarea>
														<br />
													</span>
												</label>
												<br />

												<label> Additional information to support your request - cover letter, sponsorship packet, flyer (optional .pdf document up to 5mb)<br />
													<span className="wpcf7-form-control-wrap file-additional-info">
													<input ref={(ref) => {this.fileAdditionalInfo = ref}} type="file" name="file-additional-info" size="40" className="wpcf7-form-control wpcf7-file" accept=".pdf" /><br /></span>
												</label>
												<br />

												<label> Community where funds will be used<br />
													<span className="wpcf7-form-control-wrap your-community">
														<textarea ref={(ref) => {this.community = ref}} name="your-community" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"></textarea>
														<br />
													</span>
												</label>
												<br />

												<label> Do you plan to recognize/publicize the contribution?  If so, how?<br />
													<span className="wpcf7-form-control-wrap your-recognition">
														<textarea ref={(ref) => {this.recognition = ref}} name="your-recognition" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea"></textarea>
														<br />
													</span>
												</label>
												<br />

												<p>By submitting this form, you agree to the <a href="/terms-of-use" target="_blank">terms of use</a> and <a href="/privacy-policy" target="_blank">privacy policy</a> established for this website.</p>
												<em>Please do not close this window until the screen indicates that your request has been submitted.  A confirmation email with a summary of the request will be sent to the email address provided.</em>
												<br />
												<div className="submit-wrapp">
													{this.state.loader ? (
														<>
														<input type="submit" value="Sending..." className="wpcf7-form-control wpcf7-submit" disabled="disabled" />
														<img  src={`/rings.svg`} alt="loader" className="loader" />
														</>
													) : (
														<input type="submit" value="Send" className="wpcf7-form-control wpcf7-submit" />
													)}
												</div>
												<br />

												<div className="wpcf7-response-output wpcf7-display-none wpcf7-validation-errors">
													{ this.state.formMessage }
												</div>

											</form>
										</div>
									</div>

								</div>
								<div className="uk-width-1-3@m">
									{currentPage.sidebarWidgetRelation.sidebarWidgetRelations.map((widget, index) => (
										<div className="operations-box" dangerouslySetInnerHTML={{__html: widget.content}} key={index} />
									))}
								</div>
							</div>
							</>
						) : (
							<>
								{currentPage.svgInteractiveMap && currentPage.svgInteractiveMap.svgMap ? (
									<div dangerouslySetInnerHTML={{__html: currentPage.svgInteractiveMap.svgMap}} />
								) : (
									null
								)}
								<div className="main-col" dangerouslySetInnerHTML={{__html: currentPage.content}} />
								{currentPage.contactUs && currentPage.contactUs.location ? (
									<>
										{currentPage.contactUs.location[0].addresses.map((item, index) => (
										<div className="uk-grid contact-us-grid" data-uk-grid>
											<div className="uk-width-1-2@s map(callbackfn: Function, thisArg?: any)">
												<h4>{currentPage.contactUs.location[0].leftColumnTitle}</h4>
												<div key={index} dangerouslySetInnerHTML={{__html: item.physicalAddress}}></div>
											))}
											</div>
											<div className="uk-width-1-2@s">
												<h4>{currentPage.contactUs.location[0].rightColumnTitle}</h4>
												{currentPage.contactUs.location[0].addresses.map((item, i) => (
													i === index ?
													<div key={index} dangerouslySetInnerHTML={{__html: item.mailingAddress}}></div>
													: null
												))}
											</div>
										</div>
										))}
									</>
								) : (
									null
								)}
							</>
						)}
					</div>
				</section>
			</Layout>
		)

	}
}

export default PageTemplate

export const pageQuery = graphql`
	query($id: String!) {

		wpPage(id: { eq: $id }) {
			uri
			title
			slug
			status
			content
			featuredImage {
				node {
					sourceUrl
				}
			}
			headerBackground {
				vimeoId
				videoPoster {
					sourceUrl
				}
			}
			sidebarWidgetRelation {
				sidebarWidgetRelations {
					... on WpSidebarWidgets {
						title
						content
					}
				}
			}
			contactUs {
				location {
					addresses {
						mailingAddress
						physicalAddress
					}
					leftColumnTitle
					rightColumnTitle
				}
			}
			svgInteractiveMap {
				svgMap
			}
			wpParent {
				node {
					slug
				}
			}
		}

		wpMenu(name: {eq: "About-submenu"}) {
			menuItems {
				nodes {
					url
					label
				}
			}
		}

		site {
			id
			siteMetadata {
				title
			}
		}

	}
`

